import {
  PlainObject,
  PODStateApiModel,
  SelectItemModel,
  StatePodIdPair,
} from '../../types';
import { ruleTypeLabels, RuleTypeName } from '../../constants';

export const LOAD_MORE_RULES = 10;

export enum ExpressionAttribute {
  Customer = 'customer',
  EquipmentType = 'equipmentType',
  OriginPod = 'originPod',
  DestinationPod = 'destinationPod',
  OriginState = 'originState',
  DestinationState = 'destinationState',
  PickUpDate = 'pickUpDate',
  DeliveryDate = 'deliveryDate',
  Distance = 'distance',
  Stops = 'stops',
  TransitTime = 'transitTime',
  PickUpTime = 'pickUpTime',
  DropTime = 'dropTime',
}

export const ruleExpressionAttributes = <PlainObject<SelectItemModel>>{
  [ExpressionAttribute.Customer]: {
    label: 'Customer',
    value: ExpressionAttribute.Customer,
  },
  [ExpressionAttribute.EquipmentType]: {
    label: 'Equipment type',
    value: ExpressionAttribute.EquipmentType,
  },
  [ExpressionAttribute.OriginPod]: {
    label: 'Origin POD',
    value: ExpressionAttribute.OriginPod,
  },
  [ExpressionAttribute.DestinationPod]: {
    label: 'Destination POD',
    value: ExpressionAttribute.DestinationPod,
  },
  [ExpressionAttribute.OriginState]: {
    label: 'Origin State',
    value: ExpressionAttribute.OriginState,
  },
  [ExpressionAttribute.DestinationState]: {
    label: 'Destination State',
    value: ExpressionAttribute.DestinationState,
  },
  [ExpressionAttribute.PickUpDate]: {
    label: 'Pick Up Date',
    value: ExpressionAttribute.PickUpDate,
  },
  [ExpressionAttribute.DeliveryDate]: {
    label: 'Delivery Date',
    value: ExpressionAttribute.DeliveryDate,
  },
  [ExpressionAttribute.Distance]: {
    label: 'Distance',
    value: ExpressionAttribute.Distance,
  },
  [ExpressionAttribute.Stops]: {
    label: 'Stops',
    value: ExpressionAttribute.Stops,
  },
  [ExpressionAttribute.TransitTime]: {
    label: 'Transit Time (minutes)',
    value: ExpressionAttribute.TransitTime,
  },
  [ExpressionAttribute.PickUpTime]: {
    label: 'Pick Up Time',
    value: ExpressionAttribute.PickUpTime,
  },
  [ExpressionAttribute.DropTime]: {
    label: 'Drop Time',
    value: ExpressionAttribute.DropTime,
  },
};

export const expressionAttributesItems = Object.values(
  ruleExpressionAttributes,
);

// RuleExpressionOperator
// 0- "IS EQUAL TO"
// 1- "IS NOT"
// 2- "IS ONE OF"
// 3- "IS NOT ONE OF"
// 4- "IS GREATER THAN"
// 5- "IS LESS THAN"

export enum ExpressionOperator {
  Equals, // IS EQUAL TO
  NotEquals, // IS NOT
  In, // IS EQUAL TO
  NotIn, // IS NOT
  GreaterThan, // IS GREATER THAN
  LessThan, // IS LESS THAN
  GreaterEqualThan, // IS GREATER OR EQUAL THAN
  LessEqualThan, // IS LESS OR EQUAL THAN

  Between = 99,
}

export const availableOperatorsForAttributes: PlainObject = {
  [ExpressionAttribute.Customer]: [
    ExpressionOperator.Equals,
    ExpressionOperator.NotEquals,
  ], // IS EQUAL TO | IS NOT

  [ExpressionAttribute.EquipmentType]: [
    ExpressionOperator.In,
    ExpressionOperator.NotIn,
  ], // IS ONE OF | IS NOT ONE OF
  [ExpressionAttribute.OriginPod]: [
    ExpressionOperator.In,
    ExpressionOperator.NotIn,
  ], // IS ONE OF | IS NOT ONE OF
  [ExpressionAttribute.DestinationPod]: [
    ExpressionOperator.In,
    ExpressionOperator.NotIn,
  ], // IS ONE OF | IS NOT ONE OF

  [ExpressionAttribute.OriginState]: [
    ExpressionOperator.In,
    ExpressionOperator.NotIn,
  ], // IS ONE OF | IS NOT ONE OF
  [ExpressionAttribute.DestinationState]: [
    ExpressionOperator.In,
    ExpressionOperator.NotIn,
  ], // IS ONE OF | IS NOT ONE OF

  [ExpressionAttribute.PickUpDate]: [
    ExpressionOperator.In,
    ExpressionOperator.NotIn,
  ], // IS ONE OF | IS NOT ONE OF
  [ExpressionAttribute.DeliveryDate]: [
    ExpressionOperator.In,
    ExpressionOperator.NotIn,
  ], // IS ONE OF | IS NOT ONE OF

  [ExpressionAttribute.Distance]: [
    ExpressionOperator.Equals,
    ExpressionOperator.GreaterThan,
    ExpressionOperator.LessThan,
    ExpressionOperator.Between,
  ], // IS EQUAL TO | IS LESS THAN | IS GREATER THAN
  [ExpressionAttribute.Stops]: [
    ExpressionOperator.Equals,
    ExpressionOperator.GreaterThan,
    ExpressionOperator.LessThan,
  ], // IS EQUAL TO | IS LESS THAN | IS GREATER THAN
  [ExpressionAttribute.TransitTime]: [ExpressionOperator.Between],
  [ExpressionAttribute.PickUpTime]: [ExpressionOperator.Between],
  [ExpressionAttribute.DropTime]: [ExpressionOperator.Between],
};

export const ruleTypesOptions: SelectItemModel[] = [
  {
    value: RuleTypeName.RatePerTrip,
    label: ruleTypeLabels[RuleTypeName.RatePerTrip],
  },
  {
    value: RuleTypeName.RatePerMile,
    label: ruleTypeLabels[RuleTypeName.RatePerMile],
  },
  {
    value: RuleTypeName.DATBasedRate,
    label: ruleTypeLabels[RuleTypeName.DATBasedRate],
  },
];

export const states: PODStateApiModel[] = [
  {
    PodCountryAbbr: 'USA',
    PodState: 'Alabama',
    PodStateAbbr: 'AL',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Arkansas',
    PodStateAbbr: 'AR',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Alaska',
    PodStateAbbr: 'AK',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Arizona',
    PodStateAbbr: 'AZ',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'California',
    PodStateAbbr: 'CA',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Colorado',
    PodStateAbbr: 'CO',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Connecticut',
    PodStateAbbr: 'CT',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Delaware',
    PodStateAbbr: 'DE',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'District of Columbia',
    PodStateAbbr: 'DC',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Florida',
    PodStateAbbr: 'FL',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Georgia',
    PodStateAbbr: 'GA',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Hawaii',
    PodStateAbbr: 'HI',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Idaho',
    PodStateAbbr: 'ID',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Illinois',
    PodStateAbbr: 'IL',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Indiana',
    PodStateAbbr: 'IN',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Iowa',
    PodStateAbbr: 'IA',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Kansas',
    PodStateAbbr: 'KS',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Kentucky',
    PodStateAbbr: 'KY',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Louisiana',
    PodStateAbbr: 'LA',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Maine',
    PodStateAbbr: 'ME',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Maryland',
    PodStateAbbr: 'MD',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Massachusetts',
    PodStateAbbr: 'MA',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Michigan',
    PodStateAbbr: 'MI',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Minnesota',
    PodStateAbbr: 'MN',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Mississippi',
    PodStateAbbr: 'MS',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Missouri',
    PodStateAbbr: 'MO',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Montana',
    PodStateAbbr: 'MT',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Nebraska',
    PodStateAbbr: 'NE',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Nevada',
    PodStateAbbr: 'NV',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'New Hampshire',
    PodStateAbbr: 'NH',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'New Jersey',
    PodStateAbbr: 'NJ',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'New Mexico',
    PodStateAbbr: 'NM',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'New York',
    PodStateAbbr: 'NY',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'North Carolina',
    PodStateAbbr: 'NC',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'North Dakota',
    PodStateAbbr: 'ND',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Ohio',
    PodStateAbbr: 'OH',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Oklahoma',
    PodStateAbbr: 'OK',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Oregon',
    PodStateAbbr: 'OR',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Pennsylvania',
    PodStateAbbr: 'PA',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Rhode Island',
    PodStateAbbr: 'RI',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'South Carolina',
    PodStateAbbr: 'SC',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'South Dakota',
    PodStateAbbr: 'SD',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Tennessee',
    PodStateAbbr: 'TN',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Texas',
    PodStateAbbr: 'TX',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Utah',
    PodStateAbbr: 'UT',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Vermont',
    PodStateAbbr: 'VT',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Virginia',
    PodStateAbbr: 'VA',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Washington',
    PodStateAbbr: 'WA',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'West Virginia',
    PodStateAbbr: 'WV',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Wisconsin',
    PodStateAbbr: 'WI',
  },
  {
    PodCountryAbbr: 'USA',
    PodState: 'Wyoming',
    PodStateAbbr: 'WY',
  },
  {
    PodCountryAbbr: 'CAN',
    PodState: 'Newfoundland and Labrador',
    PodStateAbbr: 'NL',
  },
  {
    PodCountryAbbr: 'CAN',
    PodState: 'Nova Scotia',
    PodStateAbbr: 'NS',
  },
  {
    PodCountryAbbr: 'CAN',
    PodState: 'New Brunswick',
    PodStateAbbr: 'NB',
  },
  {
    PodCountryAbbr: 'CAN',
    PodState: 'Quebec',
    PodStateAbbr: 'QC',
  },
  {
    PodCountryAbbr: 'CAN',
    PodState: 'Ontario',
    PodStateAbbr: 'ON',
  },
  {
    PodCountryAbbr: 'CAN',
    PodState: 'Manitoba',
    PodStateAbbr: 'MB',
  },
  {
    PodCountryAbbr: 'CAN',
    PodState: 'Saskatchewan',
    PodStateAbbr: 'SK',
  },
  {
    PodCountryAbbr: 'CAN',
    PodState: 'Alberta',
    PodStateAbbr: 'AB',
  },
  {
    PodCountryAbbr: 'CAN',
    PodState: 'British Columbia',
    PodStateAbbr: 'BC',
  },
];

export const statePodPairs: StatePodIdPair[] = [
  {
    ProvinceAbbr: 'AL',
    DatPodId: 'TN_CHA',
  },
  {
    ProvinceAbbr: 'AR',
    DatPodId: 'TX_TEX',
  },
  {
    ProvinceAbbr: 'AR',
    DatPodId: 'TN_MEM',
  },
  {
    ProvinceAbbr: 'CA',
    DatPodId: 'NV_REN',
  },
  {
    ProvinceAbbr: 'CA',
    DatPodId: 'OR_MED',
  },
  {
    ProvinceAbbr: 'CO',
    DatPodId: 'NM_ALB',
  },
  {
    ProvinceAbbr: 'CO',
    DatPodId: 'NE_NPL',
  },
  {
    ProvinceAbbr: 'DC',
    DatPodId: 'VA_ALE',
  },
  {
    ProvinceAbbr: 'DE',
    DatPodId: 'MD_BAL',
  },
  {
    ProvinceAbbr: 'FL',
    DatPodId: 'AL_MOB',
  },
  {
    ProvinceAbbr: 'GA',
    DatPodId: 'TN_CHA',
  },
  {
    ProvinceAbbr: 'GA',
    DatPodId: 'SC_GRE',
  },
  {
    ProvinceAbbr: 'GA',
    DatPodId: 'AL_MON',
  },
  {
    ProvinceAbbr: 'IA',
    DatPodId: 'SD_SXF',
  },
  {
    ProvinceAbbr: 'IA',
    DatPodId: 'IL_ROC',
  },
  {
    ProvinceAbbr: 'IA',
    DatPodId: 'NE_OMA',
  },
  {
    ProvinceAbbr: 'ID',
    DatPodId: 'WA_SPO',
  },
  {
    ProvinceAbbr: 'IL',
    DatPodId: 'IN_TER',
  },
  {
    ProvinceAbbr: 'IL',
    DatPodId: 'MO_GIR',
  },
  {
    ProvinceAbbr: 'IL',
    DatPodId: 'IN_EVA',
  },
  {
    ProvinceAbbr: 'IL',
    DatPodId: 'MO_STL',
  },
  {
    ProvinceAbbr: 'IN',
    DatPodId: 'OH_CIN',
  },
  {
    ProvinceAbbr: 'IN',
    DatPodId: 'KY_LOU',
  },
  {
    ProvinceAbbr: 'KS',
    DatPodId: 'MO_JOP',
  },
  {
    ProvinceAbbr: 'KS',
    DatPodId: 'MO_KAN',
  },
  {
    ProvinceAbbr: 'KY',
    DatPodId: 'IN_EVA',
  },
  {
    ProvinceAbbr: 'KY',
    DatPodId: 'WV_HUN',
  },
  {
    ProvinceAbbr: 'KY',
    DatPodId: 'TN_KNO',
  },
  {
    ProvinceAbbr: 'KY',
    DatPodId: 'WV_CHA',
  },
  {
    ProvinceAbbr: 'KY',
    DatPodId: 'MO_GIR',
  },
  {
    ProvinceAbbr: 'MD',
    DatPodId: 'VA_ALE',
  },
  {
    ProvinceAbbr: 'MD',
    DatPodId: 'VA_WIN',
  },
  {
    ProvinceAbbr: 'MI',
    DatPodId: 'IN_SBD',
  },
  {
    ProvinceAbbr: 'MI',
    DatPodId: 'MN_DUL',
  },
  {
    ProvinceAbbr: 'MN',
    DatPodId: 'SD_SXF',
  },
  {
    ProvinceAbbr: 'MN',
    DatPodId: 'ND_FAR',
  },
  {
    ProvinceAbbr: 'MN',
    DatPodId: 'WI_EAU',
  },
  {
    ProvinceAbbr: 'MO',
    DatPodId: 'IL_QUI',
  },
  {
    ProvinceAbbr: 'MO',
    DatPodId: 'AR_FAY',
  },
  {
    ProvinceAbbr: 'MS',
    DatPodId: 'AL_MOB',
  },
  {
    ProvinceAbbr: 'MS',
    DatPodId: 'AL_DEC',
  },
  {
    ProvinceAbbr: 'MS',
    DatPodId: 'TN_MEM',
  },
  {
    ProvinceAbbr: 'NC',
    DatPodId: 'SC_GRE',
  },
  {
    ProvinceAbbr: 'NC',
    DatPodId: 'TN_CHA',
  },
  {
    ProvinceAbbr: 'NC',
    DatPodId: 'VA_NOR',
  },
  {
    ProvinceAbbr: 'NH',
    DatPodId: 'ME_AUG',
  },
  {
    ProvinceAbbr: 'NJ',
    DatPodId: 'PA_PHI',
  },
  {
    ProvinceAbbr: 'NL',
    DatPodId: 'NF_STJ',
  },
  {
    ProvinceAbbr: 'NM',
    DatPodId: 'TX_ELP',
  },
  {
    ProvinceAbbr: 'NM',
    DatPodId: 'TX_LUB',
  },
  {
    ProvinceAbbr: 'NY',
    DatPodId: 'CT_HAR',
  },
  {
    ProvinceAbbr: 'OH',
    DatPodId: 'WV_CHA',
  },
  {
    ProvinceAbbr: 'OH',
    DatPodId: 'PA_PIT',
  },
  {
    ProvinceAbbr: 'OH',
    DatPodId: 'WV_HUN',
  },
  {
    ProvinceAbbr: 'OK',
    DatPodId: 'TX_AMA',
  },
  {
    ProvinceAbbr: 'OK',
    DatPodId: 'AR_FAY',
  },
  {
    ProvinceAbbr: 'OR',
    DatPodId: 'ID_TWI',
  },
  {
    ProvinceAbbr: 'PA',
    DatPodId: 'NY_ELM',
  },
  {
    ProvinceAbbr: 'PE',
    DatPodId: 'NB_MON',
  },
  {
    ProvinceAbbr: 'QC',
    DatPodId: 'ON_OTT',
  },
  {
    ProvinceAbbr: 'QC',
    DatPodId: 'PQ_QUE',
  },
  {
    ProvinceAbbr: 'QC',
    DatPodId: 'PQ_MON',
  },
  {
    ProvinceAbbr: 'RI',
    DatPodId: 'MA_BOS',
  },
  {
    ProvinceAbbr: 'SC',
    DatPodId: 'NC_CHA',
  },
  {
    ProvinceAbbr: 'SC',
    DatPodId: 'GA_SAV',
  },
  {
    ProvinceAbbr: 'TN',
    DatPodId: 'AL_DEC',
  },
  {
    ProvinceAbbr: 'TX',
    DatPodId: 'LA_SHR',
  },
  {
    ProvinceAbbr: 'UT',
    DatPodId: 'CO_GRA',
  },
  {
    ProvinceAbbr: 'VA',
    DatPodId: 'WV_CHA',
  },
  {
    ProvinceAbbr: 'VA',
    DatPodId: 'TN_KNO',
  },
  {
    ProvinceAbbr: 'VT',
    DatPodId: 'NH_BRI',
  },
  {
    ProvinceAbbr: 'VT',
    DatPodId: 'NY_ALB',
  },
  {
    ProvinceAbbr: 'WA',
    DatPodId: 'OR_PEN',
  },
  {
    ProvinceAbbr: 'WA',
    DatPodId: 'OR_POR',
  },
  {
    ProvinceAbbr: 'WI',
    DatPodId: 'IA_DUB',
  },
  {
    ProvinceAbbr: 'WI',
    DatPodId: 'MN_DUL',
  },
  {
    ProvinceAbbr: 'WV',
    DatPodId: 'VA_WIN',
  },
  {
    ProvinceAbbr: 'WV',
    DatPodId: 'PA_PIT',
  },
  {
    ProvinceAbbr: 'WY',
    DatPodId: 'MT_BIL',
  },
  {
    ProvinceAbbr: 'WY',
    DatPodId: 'ID_TWI',
  },
  {
    ProvinceAbbr: 'WY',
    DatPodId: 'CO_DEN',
  },
  {
    ProvinceAbbr: 'WY',
    DatPodId: 'SD_RAP',
  },
  {
    ProvinceAbbr: 'WY',
    DatPodId: 'NE_NPL',
  },
];
