import {
  ODataProps,
  PlainObject,
  PODExtendedModel,
  QuoteBaseEntityTypeUIExtended,
  RulesFiltersModel,
  StatePodIdPair,
} from '../../types';
import {
  FiltersODataResult,
  filtersToOData,
  ODataItemValueType,
} from './oDataBuilder';
import { oDateFieldAliases } from './oDataBuilder.const';
import { removeEmptyValues } from '../removeEmptyValues';
import { is } from '../is';
import { statePodPairs } from '../../features/ruleForm/RuleForm.const';
import { RailwayAlertRounded } from '@mui/icons-material';

/*
"Filter": "FilterExpressions/any(
  r: r/Attribute eq 'ShipperId' and (r/Value eq '3' or r/Value eq '5')
) and (Mode eq 1) and (UserId eq 3)"
 */

export function rulesFiltersToOData({
  filters,
  ...other
}: ODataProps<Partial<RulesFiltersModel>>): Partial<FiltersODataResult> {
  const {
    shippers,
    equipmentTypes,
    originPOD,
    destinationPOD,
    ...otherFilters
  } = filters;

  const oData = filtersToOData({
    filters: otherFilters as unknown as PlainObject<ODataItemValueType>,
    ...other,
  });

  const specFilters: PlainObject<
    Array<QuoteBaseEntityTypeUIExtended | PODExtendedModel>
  > = removeEmptyValues({
    shippers,
    equipmentTypes,
    originPOD,
    destinationPOD,
  });

  if (is.empty(specFilters)) {
    return oData;
  }

  const buildPODValueSubexpression = (
    subFilter: QuoteBaseEntityTypeUIExtended | PODExtendedModel,
  ) => {
    if (!('Id' in subFilter)) {
      return `r/Value eq '${subFilter.DatId}'`;
    }
  };
  const buildStateValueSubexpression = (state: string | undefined) => {
    if (state) {
      return `r/Value eq '${state}'`;
    }
  };
  function buildPODFilterExpressions(
    filterName: string,
    valuesPOD: (string | undefined)[],
    stateAttributeName: any,
    valuesState: (string | undefined)[],
  ): string {
    return `FilterExpressions/any(r: (r/Attribute eq '${
      oDateFieldAliases[filterName] || filterName
    }' and (${valuesPOD.join(
      ' or ',
    )})) or (r/Attribute eq '${stateAttributeName}' and (${valuesState.join(
      ' or ',
    )})))`;
  }
  // [ "FilterExpressions/any(r: r/Attribute eq 'ShipperId' and (r/Value eq '3' or r/Value eq '5'))" ]
  const expressions = Object.keys(specFilters).map((filterName) => {
    // ["r/Value eq 3", "r/Value eq 5"]
    if (filterName.indexOf('POD') != -1) {
      const valuesPOD = specFilters[filterName].map(buildPODValueSubexpression);
      const states: (string | undefined)[] = specFilters[filterName].map(
        (subFilter) => {
          if (!('Id' in subFilter)) {
            const state = subFilter.DatId.split('_')[0];
            return `${state}`;
          }
        },
      );
      // append additional states from mapping config
      specFilters[filterName].forEach((subFilter) => {
        if (!('Id' in subFilter)) {
          const additionalStates: string[] = statePodPairs
            .filter((pair) => {
              return pair.DatPodId === subFilter.DatId;
            })
            .map((pair) => pair.ProvinceAbbr);
          additionalStates.forEach((additionalState) => {
            if (states.indexOf(additionalState) === -1) {
              states.push(additionalState);
            }
          });
        }
      });

      const valuesState = states.map(buildStateValueSubexpression);
      const stateAttributeName =
        filterName === 'originPOD'
          ? oDateFieldAliases.originState
          : oDateFieldAliases.destinationState;
      return buildPODFilterExpressions(
        filterName,
        valuesPOD,
        stateAttributeName,
        valuesState,
      );
    } else {
      const values = specFilters[filterName].map(
        (subFilter: QuoteBaseEntityTypeUIExtended | PODExtendedModel) => {
          if ('Id' in subFilter) {
            return `r/Value eq '${subFilter.Id}'`;
          }
          return `r/Value eq '${subFilter.DatId}'`;
        },
      );
      return `FilterExpressions/any(r: r/Attribute eq '${
        oDateFieldAliases[filterName] || filterName
      }' and (${values.join(' or ')}))`;
    }
  });

  const expressionsQuery = expressions.join(' and ');

  return {
    ...oData,
    Filter: oData.Filter
      ? `${expressionsQuery} and ${oData.Filter}`
      : expressionsQuery,
  };
}
