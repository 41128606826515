import {
  CreateRuleFormModel,
  RuleExpressionsModel,
  RuleExpressionsValueType,
} from './RuleForm.types';
import {
  expressionsApiKeys,
  expressionsFormKeys,
  RULE_CAN_NOT_BE_CREATED_NO_FUEL_INFO,
  RuleMode,
  RuleRateActionMode,
  ruleRateActionModeToDATActionValue,
  ruleRateActionModeToDATRateValue,
  ruleTypeLabels,
  RuleTypeName,
  ruleTypeToDATRateDirection,
  ruleTypeToRuleTypeName,
} from '../../constants';
import {
  PODExtendedModel,
  QuoteBaseEntityTypeUIExtended,
  RangeFilter,
  RuleApiModel,
  RuleSaveExpressionsData,
  SelectItemModel,
} from '../../types';
import { is, removeEmptyValues, toCurrentTimezoneDate } from '../../utils';
import {
  ExpressionAttribute,
  ExpressionOperator,
  states,
} from './RuleForm.const';

export function transformRuleApiExpressionValueToForm(
  expression: RuleSaveExpressionsData,
  shippers: QuoteBaseEntityTypeUIExtended[],
  equipmentTypes: QuoteBaseEntityTypeUIExtended[],
  pods: PODExtendedModel[],
  specialDates: SelectItemModel[],
): RuleExpressionsValueType {
  if (
    expression.Attribute === expressionsApiKeys[ExpressionAttribute.Customer]
  ) {
    return (
      shippers.find(
        (shipper) => Number(shipper.Id) === Number(expression.Value),
      ) || shippers[0]
    );
  }

  if (
    expression.Attribute ===
    expressionsApiKeys[ExpressionAttribute.EquipmentType]
  ) {
    const value = (
      expression.Value ? JSON.parse(String(expression.Value)) : []
    ) as number[];

    return equipmentTypes.filter(
      (et) => value.find((v) => v === et.Id) !== undefined,
    );
  }

  if (
    expression.Attribute ===
      expressionsApiKeys[ExpressionAttribute.OriginPod] ||
    expression.Attribute ===
      expressionsApiKeys[ExpressionAttribute.DestinationPod]
  ) {
    const value = (
      expression.Value ? JSON.parse(String(expression.Value)) : []
    ) as string[];

    return pods.filter((et) => value.find((v) => v === et.DatId) !== undefined);
  }

  if (
    expression.Attribute ===
      expressionsApiKeys[ExpressionAttribute.OriginState] ||
    expression.Attribute ===
      expressionsApiKeys[ExpressionAttribute.DestinationState]
  ) {
    const value = (
      expression.Value ? JSON.parse(String(expression.Value)) : []
    ) as string[];
    return states.filter(
      (s) => value.find((v) => v === s.PodStateAbbr) !== undefined,
    );
  }

  if (
    expression.Attribute ===
      expressionsApiKeys[ExpressionAttribute.PickUpDate] ||
    expression.Attribute ===
      expressionsApiKeys[ExpressionAttribute.DeliveryDate]
  ) {
    const value = (
      expression.Value ? JSON.parse(String(expression.Value)) : []
    ) as string[];

    const dates = is.array(value) ? value : [value];

    return specialDates.filter(
      (et) => dates.find((v) => v === et.value) !== undefined,
    );
  }

  return expression.Value;
}

function transformApiExpressionsToRange(
  expressions: RuleExpressionsModel[],
): RuleExpressionsModel[] {
  const distances = expressions.filter(
    (ex) => ex.attribute === ExpressionAttribute.Distance,
  );

  const transitTimes = expressions.filter(
    (ex) => ex.attribute === ExpressionAttribute.TransitTime,
  );

  const pickUpTimes = expressions.filter(
    (ex) => ex.attribute === ExpressionAttribute.PickUpTime,
  );

  const dropTimes = expressions.filter(
    (ex) => ex.attribute === ExpressionAttribute.DropTime,
  );

  if (
    distances.length !== 2 &&
    transitTimes.length !== 2 &&
    pickUpTimes.length !== 2 &&
    dropTimes.length !== 2
  ) {
    return expressions;
  }

  let distanceGreaterThan: RuleExpressionsModel | undefined;
  let distanceLessThan: RuleExpressionsModel | undefined;
  let transitTimeGreaterThan: RuleExpressionsModel | undefined;
  let transitTimeLessThan: RuleExpressionsModel | undefined;
  let pickUpTimeGreaterThan: RuleExpressionsModel | undefined;
  let pickUpTimeLessThan: RuleExpressionsModel | undefined;
  let dropTimeGreaterThan: RuleExpressionsModel | undefined;
  let dropTimeLessThan: RuleExpressionsModel | undefined;

  distances.forEach((d) => {
    if (d.operator === ExpressionOperator.GreaterEqualThan) {
      distanceGreaterThan = d;
    } else if (d.operator === ExpressionOperator.LessThan) {
      distanceLessThan = d;
    }
  });

  transitTimes.forEach((t) => {
    if (t.operator === ExpressionOperator.GreaterEqualThan) {
      transitTimeGreaterThan = t;
    } else if (t.operator === ExpressionOperator.LessThan) {
      transitTimeLessThan = t;
    }
  });

  pickUpTimes.forEach((t) => {
    if (t.operator === ExpressionOperator.GreaterEqualThan) {
      pickUpTimeGreaterThan = t;
    } else if (t.operator === ExpressionOperator.LessThan) {
      pickUpTimeLessThan = t;
    }
  });

  dropTimes.forEach((t) => {
    if (t.operator === ExpressionOperator.GreaterEqualThan) {
      dropTimeGreaterThan = t;
    } else if (t.operator === ExpressionOperator.LessThan) {
      dropTimeLessThan = t;
    }
  });

  if (
    (!distanceGreaterThan || !distanceLessThan) &&
    (!transitTimeGreaterThan || !transitTimeLessThan) &&
    (!pickUpTimeGreaterThan || !pickUpTimeLessThan) &&
    (!dropTimeGreaterThan || !dropTimeLessThan)
  ) {
    return expressions;
  }

  return expressions
    .map((ex): RuleExpressionsModel | null => {
      const isDistance = ex.attribute === ExpressionAttribute.Distance;
      const isTransitTime = ex.attribute === ExpressionAttribute.TransitTime;
      const isPickUpTime = ex.attribute === ExpressionAttribute.PickUpTime;
      const isDropTime = ex.attribute === ExpressionAttribute.DropTime;

      if (!isDistance && !isTransitTime && !isPickUpTime && !isDropTime) {
        return ex;
      }

      if (ex.operator === ExpressionOperator.GreaterEqualThan) {
        return null;
      }

      if (ex.operator === ExpressionOperator.LessThan) {
        let from;
        switch (true) {
          case isDistance:
            from = distanceGreaterThan?.value;
            break;
          case isTransitTime:
            from = transitTimeGreaterThan?.value;
            break;
          case isPickUpTime:
            from = pickUpTimeGreaterThan?.value;
            break;
          case isDropTime:
            from = dropTimeGreaterThan?.value;
        }

        let to;
        switch (true) {
          case isDistance:
            to = distanceLessThan?.value;
            break;
          case isTransitTime:
            to = transitTimeLessThan?.value;
            break;
          case isPickUpTime:
            to = pickUpTimeLessThan?.value;
            break;
          case isDropTime:
            to = dropTimeLessThan?.value;
        }

        return {
          ...ex,
          operator: ExpressionOperator.Between,
          value: {
            from: from,
            to: to,
          } as RangeFilter,
        };
      }

      return ex;
    })
    .filter((v) => v) as RuleExpressionsModel[];
}

export function transformRuleApiToForm(
  {
    Name,
    Description,
    Mode,
    RateActionMode,
    RateAmount,
    StartDate,
    EndDate,
    Type,
    Expressions,
    BidMaxLimit,
    BidMinLimit,
  }: RuleApiModel,
  shippers: QuoteBaseEntityTypeUIExtended[],
  equipmentTypes: QuoteBaseEntityTypeUIExtended[],
  pods: PODExtendedModel[],
  specialDates: SelectItemModel[],
): Partial<CreateRuleFormModel> {
  return removeEmptyValues({
    ruleName: Name,
    description: Description,
    mode: Mode,
    type:
      Mode === RuleMode.AutoIgnore ? undefined : ruleTypeToRuleTypeName[Type],
    startDate: toCurrentTimezoneDate(StartDate),
    endDate: toCurrentTimezoneDate(EndDate),

    rateAmount: RateAmount,
    datRate: ruleTypeToDATRateDirection[Type],
    datRateAction:
      ruleRateActionModeToDATActionValue[
        RateActionMode === null
          ? RuleRateActionMode.AddPercentage
          : RateActionMode
      ],
    rateValue:
      ruleRateActionModeToDATRateValue[
        RateActionMode === null
          ? RuleRateActionMode.AddPercentage
          : RateActionMode
      ],

    bidMinLimit: BidMinLimit,
    bidMaxLimit: BidMaxLimit,

    expressions: transformApiExpressionsToRange(
      (Expressions || []).map(
        (expression): RuleExpressionsModel => ({
          attribute: expressionsFormKeys[expression.Attribute],
          operator: expression.Operator,
          value: transformRuleApiExpressionValueToForm(
            expression,
            shippers,
            equipmentTypes,
            pods,
            specialDates,
          ),
          _rowId: expressionsFormKeys[expression.Attribute],
        }),
      ),
    ),
  });
}

export function getExpressionByAttribute<T = RuleExpressionsValueType>(
  expressions?: RuleExpressionsModel[],
  attributeName?: string | number,
): RuleExpressionsModel<T> | undefined {
  if (!expressions) {
    return undefined;
  }

  return expressions.find(
    (expr) => expr.attribute === attributeName,
  ) as unknown as RuleExpressionsModel<T>;
}

export function getCustomerExpression(
  expressions?: RuleExpressionsModel[],
): RuleExpressionsModel | undefined {
  return getExpressionByAttribute(expressions, ExpressionAttribute.Customer);
}

export function getRulePublishError(type?: RuleTypeName): string {
  if (!type) {
    return '';
  }

  return `${ruleTypeLabels[type]} ${RULE_CAN_NOT_BE_CREATED_NO_FUEL_INFO}`;
}
