import { PODStateApiModel } from '../types/pod';

export function sortByStateFullName(
  a: PODStateApiModel,
  b: PODStateApiModel,
): number {
  if (a.PodState < b.PodState) return -1;
  if (a.PodState > b.PodState) return 1;
  return 0;
}
