import { SetPriorityApiModel, SetPriorityInputModel } from './rules.types';
import {
  PlainObject,
  PODApiModel,
  PODExtendedModel,
  RuleSaveData,
  RuleSaveExpressionsData,
} from '../../types';
import {
  CreateRuleFormModel,
  RuleExpressionsValueType,
} from '../../features/ruleForm/RuleForm.types';
import {
  is,
  podNameFormatter,
  removeEmptyValues,
  toYearMonthDateString,
} from '../../utils';
import {
  DatActionValue,
  DatRateDirection,
  DATRateValue,
  expressionsApiKeys,
  RulePriority,
  RuleRateActionMode,
  RuleStatus,
  RuleType,
  RuleTypeName,
} from '../../constants';
import {
  ExpressionAttribute,
  ExpressionOperator,
} from '../../features/ruleForm/RuleForm.const';
import flatten from 'lodash/flatten';

export function RulePodResponseToData(pods: PODApiModel[]): PODExtendedModel[] {
  return pods.map(
    (value): PODExtendedModel => ({
      ...value,
      value: value.DatId,
      label: podNameFormatter(value),
    }),
  );
}

export function getRuleType(
  type: RuleTypeName,
  datRate: DatRateDirection,
): RuleType {
  if (type === RuleTypeName.DATBasedRate) {
    if (datRate === DatRateDirection.Avg) {
      return RuleType.DatAvgBased;
    }

    return datRate === DatRateDirection.Max
      ? RuleType.DatMaxBased
      : RuleType.DatMinBased;
  }

  return type as number as RuleType;
}

export function getRateActionMode(
  datRateAction: DatActionValue,
  rateValue: DATRateValue,
): RuleRateActionMode {
  if (rateValue === DATRateValue.USD) {
    return datRateAction === DatActionValue.Increase
      ? RuleRateActionMode.AddAbsolute
      : RuleRateActionMode.SubstractAbsolute;
  }

  return datRateAction === DatActionValue.Increase
    ? RuleRateActionMode.AddPercentage
    : RuleRateActionMode.SubstractPercentage;
}

export function mapExpressionsToApi(
  expressions: CreateRuleFormModel['expressions'],
): RuleSaveExpressionsData[] {
  const getValue = (
    attribute: ExpressionAttribute,
    value: RuleExpressionsValueType,
  ): number | string => {
    if (Array.isArray(value)) {
      return JSON.stringify(
        (value as PlainObject[]).map((v) => {
          if ('Id' in v) {
            return v.Id;
          }
          if ('DatId' in v) {
            return v.DatId;
          }
          if ('PodStateAbbr' in v) {
            return v.PodStateAbbr;
          }
          return v.value;
        }),
      );
    }

    if (value && is.object(value) && 'Id' in value) {
      return String(value.Id);
    }

    return value as string;
  };

  return flatten(
    expressions.map(
      (
        expr,
      ):
        | RuleSaveExpressionsData
        | null
        | Array<RuleSaveExpressionsData | null> => {
        if (
          expr.attribute === undefined ||
          expr.operator === undefined ||
          expr.value === undefined
        ) {
          return null;
        }

        if (is.rangeFilter(expr.value)) {
          return [
            is.nullOrUndefined(expr.value.from)
              ? null
              : {
                  Attribute: expressionsApiKeys[expr.attribute],
                  Operator: ExpressionOperator.GreaterEqualThan,
                  Value: expr.value.from,
                },
            is.nullOrUndefined(expr.value.to)
              ? null
              : {
                  Attribute: expressionsApiKeys[expr.attribute],
                  Operator: ExpressionOperator.LessThan,
                  Value: expr.value.to,
                },
          ];
        }

        return {
          Attribute: expressionsApiKeys[expr.attribute],
          Operator: expr.operator,
          Value: expr.value && getValue(expr.attribute, expr.value),
        };
      },
    ),
  ).filter((v) => v) as RuleSaveExpressionsData[];
}

export function transformRuleFormToData(
  {
    ruleName,
    description,
    mode,
    priority,
    recalculateBids,
    type,
    startDate,
    endDate,

    expressions,

    rateAmount,
    datRate,
    datRateAction,
    rateValue,

    bidMinLimit,
    bidMaxLimit,
  }: Partial<CreateRuleFormModel>,
  status?: RuleStatus,
): Partial<RuleSaveData> {
  return removeEmptyValues({
    Status: status,

    Name: ruleName,
    Description: description,
    Mode: mode,
    HighPriority: priority && priority === RulePriority.Higher,
    RecalculateBids: recalculateBids,

    StartDate: startDate && toYearMonthDateString(startDate),
    EndDate: endDate && toYearMonthDateString(endDate),

    Type:
      !is.nullOrUndefined(type) && !is.nullOrUndefined(datRate)
        ? getRuleType(type, datRate)
        : undefined,
    RateAmount: rateAmount ? parseFloat(rateAmount) : undefined,
    RateActionMode:
      type === RuleTypeName.DATBasedRate &&
      !is.nullOrUndefined(datRateAction) &&
      !is.nullOrUndefined(rateValue)
        ? getRateActionMode(datRateAction, rateValue)
        : undefined,

    BidMinLimit: bidMinLimit,
    BidMaxLimit: bidMaxLimit,

    Expressions: expressions && mapExpressionsToApi(expressions),
  });
}

export function getNewPriorities({
  ruleId,
  priorityIndex,
  rulesList,
}: SetPriorityInputModel): SetPriorityApiModel {
  const rules = rulesList.filter((v) => v.Id !== ruleId);

  const previousRule = rules[priorityIndex - 1];

  if (!previousRule) {
    return {
      RuleId: ruleId,
    };
  }

  return {
    RuleId: ruleId,
    PreviousRuleId: previousRule.Id,
  };
}
