import React, { useMemo } from 'react';
import { Box, IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  ExtendedRuleEnum,
  FormikEntity,
  SelectItemModel,
} from '../../../../types';
import { UICell, UIRow } from '../../../../components/uiGrid';
import { CreateRuleFormModel } from '../../RuleForm.types';
import { RuleExpressionsItem } from '../ruleExpressionsItem/RuleExpressionsItem';
import { useAppSelector } from '../../../../redux/hooks';
import { rulesSelectors } from '../../../../redux/rules';
import { filtersDataSelectors } from '../../../../redux/filtersData';
import {
  ExpressionAttribute,
  expressionAttributesItems,
} from '../../RuleForm.const';

export interface RuleExpressionsFormProps {
  formik: FormikEntity<CreateRuleFormModel>;
  ruleModes: ExtendedRuleEnum[];
  specialDates: ExtendedRuleEnum[];
  expressionOperators: ExtendedRuleEnum[];
}

export function RuleExpressionsForm({
  formik,
  ...props
}: RuleExpressionsFormProps): JSX.Element {
  const pods = useAppSelector(rulesSelectors.getRulesPODs);
  const { shippers = [], equipmentTypes = [] } = useAppSelector(
    filtersDataSelectors.getFilters,
  );

  const ruleExpressions = formik.values.expressions;

  const removeRuleExpression = (index: number) => {
    const list = [...ruleExpressions];
    list.splice(index, 1);

    formik.setFieldValue('expressions', list);
  };

  const attributeOptions = useMemo(() => {
    function isAttributeDisabled(item: SelectItemModel): boolean {
      if (
        item.value == ExpressionAttribute.OriginPod &&
        formik.values.expressions.some(
          (i) => i.attribute === ExpressionAttribute.OriginState,
        )
      ) {
        return true;
      }
      if (
        item.value == ExpressionAttribute.OriginState &&
        formik.values.expressions.some(
          (i) => i.attribute === ExpressionAttribute.OriginPod,
        )
      ) {
        return true;
      }
      if (
        item.value == ExpressionAttribute.DestinationPod &&
        formik.values.expressions.some(
          (i) => i.attribute === ExpressionAttribute.DestinationState,
        )
      ) {
        return true;
      }
      if (
        item.value == ExpressionAttribute.DestinationState &&
        formik.values.expressions.some(
          (i) => i.attribute === ExpressionAttribute.DestinationPod,
        )
      ) {
        return true;
      }
      return formik.values.expressions.some((i) => i.attribute === item.value);
    }
    return expressionAttributesItems.map((item) => ({
      ...item,
      disabled: isAttributeDisabled(item),
    }));
  }, [formik.values.expressions]);

  return (
    <Box width="100%">
      {ruleExpressions.map((expression, index) => (
        <UIRow size="m" key={expression._rowId || index}>
          <UICell colSpan={12}>
            <RuleExpressionsItem
              key={index}
              pods={pods}
              formik={formik}
              index={index}
              shippers={shippers}
              equipmentTypes={equipmentTypes}
              attributeOptions={attributeOptions}
              {...props}
            />
          </UICell>
          <UICell display="flex" alignItems="start" paddingTop="20px">
            <IconButton
              type="button"
              size="small"
              disabled={index === 0}
              onClick={() => removeRuleExpression(index)}
            >
              <DeleteOutlineIcon fontSize="small" />
            </IconButton>
          </UICell>
        </UIRow>
      ))}
    </Box>
  );
}
